@use 'src/scss/themes/_custom-color.scss' as customcolor;

.material-icons {
  font-size: 1.25rem;
}

.mdc-button .mat-icon {
  height: auto !important;
}

td,
ul,
ol {
  .mat-mdc-icon-button {
    color: customcolor.$system-dark;
  }
}

.mat-accent .mat-icon {
  color: customcolor.$accent;
}

.drag_handle {
  color: customcolor.$system-dark;
  cursor: move;
  margin-right: 8px;
}

.mat-icon {
  line-height: 1.375rem;
}

.mdc-tooltip__surface {
  text-align: left !important;
}
