@use 'src/scss/themes/_custom-color.scss' as customcolor;

.field {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  gap: 16px;

  .field-name {
    flex-basis: 8rem;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: left;
    padding-top: 1rem;
    color: customcolor.$system-dark;
  }

  .field-value {
    flex: 1;
    width: 100%;
    position: relative;

    .value-item {
      display: flex;
      margin-right: 24px;
      gap: 4px;

      .material-icons {
        padding-top: 1px;
        color: customcolor.$primary-light;
      }
    }
  }
}

app-ques-viewer p {
  margin-block-start: 0 !important;
}

.field + .chapter {
  margin-top: 3rem;
}

.field-group {
  margin-top: 1.2rem;
  margin-bottom: 1.8rem;

  + .field-group {
    margin-top: 3.6rem;
  }
  th {
    padding-right: 16px !important;
  }
  h4 {
    margin: 1rem 0 0 0;
    color: customcolor.$primary-light;
  }
}

.field-only {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
}

.abreast {
  display: flex;
  gap: 8px;
}

.superscript {
  font-size: 0.6rem;
  vertical-align: super;
}

.field-checkbox {
  padding-top: 8px;

  .mdc-checkbox__checkmark {
    color: #fff !important;
  }
}

.field-hint {
  margin-top: -1rem;
  font-size: 0.875rem;
}

/* 在不同尺寸下的設定 */
@media (max-width: 599.98px) {
  .field {
    .field-name {
      color: customcolor.$system-darker;
      font-weight: 500;
      font-size: 1.2rem;
      flex-basis: 0;
      margin-right: 0;
    }

    .field-value {
      order: 3;
      margin-left: 0;
      flex-basis: 1rem;
    }
  }
}
