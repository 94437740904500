@use 'src/scss/themes/_custom-color.scss' as customcolor;
@use 'src/scss/base/_typesetting.scss' as typesetting;

.mat-accordion {
  & > section {
    padding: 0 24px 0 8px;
    background-color: #e9e9e9;
  }

  .mat-expansion-panel {
    box-shadow: none !important;
    border-radius: 0 !important;
    border-bottom: 1px customcolor.$system-divider solid;

    .mat-expansion-panel-header {
      border-bottom: none !important;
      padding: 4px 16px 4px 8px;
      height: auto;
      min-height: 48px;

      .mat-mdc-icon-button {
        color: customcolor.$system-dark;
      }
    }

    .mat-expansion-panel-body {
      border-top: 1px customcolor.$system-divider solid;
      background-color: #fafafa;
      font-size: 1rem;
      letter-spacing: normal;
    }
  }
}

.panel-table {
  .mat-panel-title {
    @extend %flex_row_between_center;
  }

  & > section {
    @extend %flex_row_between_center;

    height: 56px;
    color: customcolor.$system-dark;
    white-space: nowrap;
  }
}

.list-table-panel {
  .mat-expansion-panel-header {
    padding: 4px 16px !important;
  }

  .list-table-panel-header {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    gap: 8px;
    margin-right: 8px;
  }
}

.expansion-card > .mat-expansion-panel {
  margin-top: 44px !important;
  box-shadow: none;
  background: transparent;
  border: none !important;

  & > .mat-expansion-panel-header {
    padding-left: 8px;
    margin-bottom: 8px;
    height: auto !important;

    .mat-expansion-panel-header-description {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 44px;
    }
  }

  & > .mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.panel-header-between {
  .mat-expansion-panel-header-description {
    flex-grow: unset !important;
    flex-basis: unset !important;
  }
}

.flex-clean {
  flex-grow: unset !important;
  flex-basis: auto !important;
}

.panel-border {
  border-top: 1px customcolor.$system-divider solid;
}
