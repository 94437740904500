@use 'src/scss/themes/_custom-color.scss' as customcolor;

mat-stepper + .mat-mdc-card {
  margin-top: 24px;
}

.mat-step-header {
  &.cdk-program-focused {
    background-color: transparent;
  }

  .mat-step-icon {
    background-color: customcolor.$system-light;
  }

  .mat-step-icon-selected {
    background-color: customcolor.$primary !important;
  }
}

// horizontal
.mat-horizontal-stepper-header {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.disable-header-nav .mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-horizontal-content-container {
  padding: 0 !important;

  .mat-mdc-dialog-actions {
    min-height: 64px !important;
  }

  .mat-mdc-card-actions {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.mat-stepper-horizontal-line {
  margin: 0 -8px !important;
}

// vertical
.mat-vertical-stepper-header {
  padding: 16px !important;
}

.mat-vertical-content {
  padding: 0 0 24px 24px !important;
}

.mat-stepper-vertical-line {
  margin: 8px 0;

  &::before {
    left: -8px !important;
  }
}
