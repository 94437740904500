@use '@angular/material' as mat;
@use './tiffanyBrown-palette' as customPalette;

/*
  定義主題調色盤
*/
$custom-primary: mat.m2-define-palette(mat.$m2-brown-palette);
$custom-accent: mat.m2-define-palette(customPalette.$custom-tiffany);
$custom-warn: mat.m2-define-palette(mat.$m2-red-palette);

// 主色
$primary-border: mat.m2-get-color-from-palette($custom-primary, 100);
$primary-lighter: mat.m2-get-color-from-palette($custom-primary, 300);
$primary-light: mat.m2-get-color-from-palette($custom-primary, 400);

$primary: mat.m2-get-color-from-palette($custom-primary, 500);

$primary-dark: mat.m2-get-color-from-palette($custom-primary, 700);
$primary-darker: mat.m2-get-color-from-palette($custom-primary, 800);

// 強調色
$accent: mat.m2-get-color-from-palette($custom-accent, 500);

//灰色調
$system-white: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 50);
$surface: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 100);
$system-divider: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 200);
$system-lighter: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 300);
$system-light: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 400);
$system: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 500);
$system-dark: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 600);
$system-darker: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 800);

//輔助色：黃色
$colorMark: mat.m2-get-color-from-palette(mat.$m2-amber-palette, A100);
$mark: mat.m2-get-color-from-palette(mat.$m2-amber-palette, 600);

//提醒色：紅色
$warn: mat.m2-get-color-from-palette(mat.$m2-red-palette, 500);
$warn-dark: mat.m2-get-color-from-palette(mat.$m2-red-palette, 900);
$warn-lighter: mat.m2-get-color-from-palette(mat.$m2-red-palette, 50);

/* 以下為系統常用：color */

.color-alert {
  color: orange !important;
}

.color-warn {
  color: $warn-dark !important;

  &::marker {
    color: $warn-dark !important;
  }
}

.color-white {
  color: #fff !important;
}

.color-system {
  color: $system !important;
}

.color-system-dark {
  color: $system-dark !important;
}

.color-grey-white {
  color: $system-white;
}

.color-grey-blue {
  color: #78909c;
}

.color-grey-red {
  color: #e38c7a !important;
}

.color-blue {
  color: #1565c0;
}

.color-accent {
  color: $accent !important;
}

.color-primary {
  color: $primary-light !important;
}

/* 以下為系統常用：background-color */

.bgcolor-primary {
  background-color: $primary-light !important;
}

.bgcolor-highlight {
  background-color: #fff8e1 !important;
}

.bgcolor-emphasize {
  background-color: $mark !important;
}

.bgcolor-warn {
  background-color: $warn-lighter !important;
}

.bgcolor-system {
  background-color: $system !important;
}

/*----- 單位 chip 顏色設定 -----*/

.topic {
  width: 16px;
  height: 44px;
  margin: auto 8px auto 0;
}

/* 霧峰家園 WF*/
.bgColor_WF {
  background-color: #91d4a9 !important;
}

/* 愛心家園 LH*/
.bgColor_LH {
  background-color: #ffa07d !important;
}

/* 啟智學園 SE */
.bgColor_SE {
  background-color: #db899d !important;
}

/* 雲端 CD */
.bgColor_CD {
  background-color: #f4ac23 !important;
}

/* 寶寶班 BABY */
.bgColor_BABY {
  background-color: #a8dff1 !important;
}

/* 稻香發展中心 RF */
.bgColor_RF {
  background-color: $system !important;
}

/* 沙鹿小作所 SLWS */
.bgColor_SLWS {
  background-color: $system !important;
}
