@use 'src/scss/themes/_custom-color.scss' as customcolor;

li::marker,
.marker {
  color: customcolor.$primary-lighter;
}

// 自己模擬的li
.marker {
  color: #a1887f;
  margin-right: 12px;
  width: 1.5rem;
  display: inline-block;
  text-align: right;
}

.li-nocolor li::marker {
  color: #757575;
}

ol {
  padding-inline-start: 1rem;
  margin: 0 0 0 4px;

  li {
    padding-left: calc(1rem - 4px);
    margin-bottom: 0.25rem;
  }
}

mat-stepper ol {
  padding-left: 1.5rem !important;
}

ul {
  padding-inline-start: 1rem;
  margin: 0;
  list-style-type: '\25fc';

  li {
    padding-left: 1rem;
    margin-bottom: 0.25rem;
  }
}

.no-marker {
  list-style-type: none;
  padding-inline-start: 0;
  padding-left: 0 !important;
  margin: 0;

  li {
    padding-left: 0;
  }
}

.li-viewer {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

//自己模擬得標號，去樣式版
.li-marker {
  color: #a1887f;
  display: inline-block;
}

.iconlist,
.iconlist-nohover {
  padding-left: 16px;

  ol {
    margin-top: 1rem;
    margin-bottom: 24px;

    li {
      text-align: justify;
      position: relative;
      padding-top: 4px;
      padding-bottom: 4px;
      min-height: 40px;
      margin-bottom: 16px;

      .btn-bullet {
        display: none;
        background-color: #fafafa !important;
        position: absolute;
        left: -40px;
        top: -10px;
        color: customcolor.$system-dark;
      }

      th {
        white-space: nowrap;
      }

      label:hover {
        cursor: pointer;
        div {
          background-color: whitesmoke;
        }
      }
    }

    li:hover button {
      display: block;
    }
  }
}

.editable-list {
  padding-left: 16px;

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 24px;

    li {
      text-align: justify;
      position: relative;
      margin-bottom: 8px;

      .btn-bullet {
        display: none;
        background-color: #fff !important;
        position: absolute;
        top: -12px;
        left: -28px;
        color: customcolor.$system-dark;
      }

      label {
        div {
          padding: 2px 0.75rem;
        }
      }

      label:hover {
        cursor: pointer;
        div {
          background-color: whitesmoke;
        }
      }
    }

    li:hover button {
      display: block;
    }
  }
}

.reference {
  li,
  a {
    color: customcolor.$primary-lighter;
  }

  li {
    list-style-type: none;
  }
}

.add-multi {
  width: 100%;
  display: flex;
  gap: 8px;

  app-text-multi-lines {
    flex: 1;
  }
}

.drag-text-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;

  .add-multi {
    .drag_handle {
      margin-top: 0.875rem;
    }

    .li-marker,
    .drag-text-preview {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 600px) {
  .drag-text-list {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}
