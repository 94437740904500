/*----- Dialog -----*/
.mat-mdc-dialog-actions {
  justify-content: space-between;
}

.full-dialog {
  .ng-star-inserted {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .mat-mdc-dialog-content {
    flex: 1;
    max-height: inherit !important;
  }
}

.dialog-tool-bar {
  flex: 0;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .up_button {
    margin: 0 !important;
  }
}
