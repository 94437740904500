@use 'sass:math' as math;

$base-font-size: 1rem;
$base-font-ratio: 1.25;

@function define-font($lv, $font-weight: 500) {
  $fontsize: math.pow($base-font-ratio, $lv - 1) * $base-font-size;
  $line-height: 1.25;

  @if ($lv == 0) {
    $fontsize: calc($base-font-size / $base-font-ratio);
  }

  @if ($font-weight < 500) {
    $line-height: 1.5;
  }

  @return (
    font-size: $fontsize,
    line-height: $line-height,
    font-weight: $font-weight,
    font-family: 'Noto Sans TC'
  );
}

.normal {
  font-weight: normal;
}
