// 手指
.icon-pointer {
  color: #98635e !important;

  &::before {
    content: '☞';
    font-size: 1.5rem;
    margin-right: 8px;
    vertical-align: middle;
  }
}

.indent-top-2 {
  vertical-align: text-top;
  margin-top: -2px;
}

.indent-top-8 {
  margin-top: -8px;
}
