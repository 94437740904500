.mat-mdc-chip.abled {
  opacity: 1 !important;
}

.color-wait {
  background-color: #f6c871 !important;

  .mat-mdc-chip-action-label {
    color: #fff !important;
  }
}

.chip-list-view {
  pointer-events: none;

  .color-red {
    color: #fff;
    background-color: #e57373;
  }

  .color-blue {
    color: #fff;
    background-color: #64b5f6;
  }
}
