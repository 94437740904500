.photo-proof {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 1rem;

  .proof-frame {
    flex: 1;
  }
}

@media (max-width: 600px) {
  .photo-proof {
    flex-direction: column;
  }
}
