@use 'src/scss/themes/_custom-color.scss' as customcolor;

.file-preview-item-container {
  display: flex;
  flex-direction: column;

  & + .file-preview-item-container {
    margin-top: 0.5rem;
  }
}

.file-preview-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  & + .file-preview-item {
    margin-top: 0.5rem;
  }

  .list-item-id {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-size: 0.8rem;
    color: customcolor.$primary-lighter;
  }

  app-file-preview {
    flex: 1;
  }
}
