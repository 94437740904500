@use 'src/scss/themes/_custom-color.scss' as customcolor;

/* 上傳題共用樣式 */
.up_frame {
  width: 100%;
  border-radius: 10px;
  border: 2px customcolor.$system-divider solid;
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 1rem 0;
}

.up_range {
  height: 100%;
  border-radius: 10px;
  border: 3px customcolor.$system-divider dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: customcolor.$system;
  flex-direction: column;
}

.up_button {
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  padding: 0 15px;
  height: 34px;
  line-height: 34px;
  border-radius: 4px;
  background-color: customcolor.$accent;
  color: #fff;
  margin: 1rem auto;
}
