@use 'src/scss/themes/_custom-color.scss' as customcolor;

// 提示線設定
.sub-item {
  border-left: 4px solid transparent;
  padding-left: 1rem;
  margin-left: calc(-1rem - 4px);
}

//panel-title 用，防止線被切掉
.sub-item-indent {
  border-left: 4px solid transparent;
  padding-left: 1rem;
  margin-left: -4px;
}
.mat-expansion-panel-header .mat-content {
  overflow: inherit !important;
}

//提示線顏色

.highlight-border {
  border-color: customcolor.$colorMark !important;
}

.system-border {
  border-color: customcolor.$system-lighter !important;
}

.separator {
  padding-bottom: 4rem;

  & + .separator {
    border-top: 1px solid customcolor.$system-divider !important;
  }
}
