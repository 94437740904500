@use 'src/scss/themes/_custom-color.scss' as customcolor;

.description-step + .description-step {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid customcolor.$system-lighter;
}

.description-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  .mat-mdc-card {
    cursor: pointer;

    & + .mat-mdc-card {
      margin-top: 0 !important;
    }
  }

  .mat-mdc-card.center {
    align-items: center !important;
  }
}

/* XSmall - 小於600px */
@media (max-width: 599.98px) {
  .description-layout {
    grid-template-columns: repeat(1, 1fr);

    .mat-mdc-card {
      height: 13rem;
    }
  }
}
/* Small - 大於等於600，小於960 (包含Tablet)
   在此處為了題目最佳呈現效果，將寬度設為600-1120之間
*/

@media (min-width: 600px) and (max-width: 1119.98px) {
  .description-layout {
    .mat-mdc-card {
      height: 12rem;
    }
  }
}

/* Medium	 - 大於等於960，小於1280
   在此處為了題目最佳呈現效果，將寬度設為1120-1120之間
*/
@media (min-width: 1120px) and (max-width: 1279.98px) {
  .description-layout {
    .mat-mdc-card {
      height: 9rem;
    }
  }
}
/* Large - 大於1280 */
@media (min-width: 1280px) {
  .description-layout {
    .mat-mdc-card {
      height: 8rem;
    }
  }
}
