@use 'src/scss/themes/_custom-color.scss' as customcolor;

/*----- list -----*/

.underline {
  .mat-mdc-list-item + .mat-mdc-list-item {
    border-top: 1px solid customcolor.$system-divider;
  }

  & + .underline {
    border-top: 1px solid customcolor.$system-divider;
  }
}

.mat-mdc-list-item {
  a {
    height: 100%;
  }
}

.list-more {
  a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.checkbox-list {
  .mat-mdc-list-item:hover {
    background: whitesmoke !important;
    cursor: pointer;
  }
}

// 預設勾選框對齊右側
.checkbox-list {
  .mdc-list-item__primary-text {
    display: flex;
    width: 100%;
  }
}

.checkbox-list-title {
  span {
    color: customcolor.$primary-light !important;
  }
}

.checkbox-list-item {
  span {
    padding-left: 24px !important;
  }
}

.panel-list {
  .mat-expansion-panel-header {
    height: 48px;
  }

  .mat-expansion-panel-body {
    padding: 0 0 1rem;
  }
}

.mat-mdc-selection-list {
  .mat-mdc-list-item {
    height: auto !important;
    padding-left: 0;
  }

  .mdc-list-item__primary-text {
    white-space: pre-wrap;
    text-align: justify;
    display: inline-block;
    padding-top: 8px;
  }

  .mdc-list-item__start,
  .mdc-list-item__end {
    align-self: flex-start !important;
    margin: 0 !important;
  }

  .mdc-checkbox__checkmark {
    color: #fff !important;
  }
}

// 當class="auto-line"時，忽略list預設高度
.auto-line.mat-mdc-list-item {
  height: auto !important;
}

.mdc-list-item__primary-text,
.mat-mdc-subheader {
  > span {
    padding: 4px;
  }
}
