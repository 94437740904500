@use 'src/scss/themes/_custom-color.scss' as customcolor;
@use 'src/scss/base/_typesetting.scss' as typesetting;

/*----- 卡片 -----*/
.mat-mdc-card {
  box-shadow: 1px 1px 4px 0 #e3e3e3 !important;
  overflow: hidden;
  padding: 16px;
}

.mat-mdc-card + .chapter {
  margin-top: 24px;
}

.card-header-tool {
  @extend .flex_row_between_start;
  button {
    color: customcolor.$system-dark;
  }
}

.dataView {
  .section1 {
    color: customcolor.$primary-light;
  }

  .section1 + .section2 {
    margin-top: 1rem;
  }

  .section2 + .section2 {
    margin-top: 3rem;
    font-weight: bolder;
  }
}

.mat-mdc-card + .mat-mdc-card,
.mat-card + app-record-list {
  display: block;
  margin-top: 24px !important;
}

mat-card.data-warn + mat-card.spac-top-0 {
  margin-top: -4px !important;
  border-radius: 0 0 4px 4px;
}

.mat-mdc-card-header {
  position: relative;
}

.subTitle {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    flex: 1;
  }

  h2 {
    flex: 1;
  }

  button {
    color: customcolor.$system-dark;
  }
}

.mat-mdc-card-footer {
  padding: 1rem;
}
.mat-mdc-card-footer span + span {
  margin-left: 1rem;
}

.mat-mdc-card-actions {
  gap: 1rem;
}

.action-bar {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 64px;
}

.data_empty {
  margin: 24px auto !important;
  text-align: center;
  color: customcolor.$system-dark;
}

.data_empty_td {
  border-bottom: 1px customcolor.$system-divider solid;
}

.data_review {
  padding: 0.5rem;
  background-color: #fff9f3;
  border-radius: 8px;
  text-align: justify;
  text-justify: distribute;
  text-indent: 0;
  margin-bottom: 1.5rem;
}

.data_ps {
  display: inline-block;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: justify;
  text-indent: 0;
  margin-top: 8px;
}

mat-card.data_tip {
  display: flex !important;
  flex-direction: row;
  gap: 8px;
  background-color: #cdeedb;
  text-align: justify;
  align-items: center;

  .material-icons {
    color: #006644;
  }
}

mat-card.data-warn {
  display: flex !important;
  flex-direction: row;
  gap: 8px;
  color: #fff;
  background-color: customcolor.$warn-dark;
  align-items: center;

  .mat-mdc-outlined-button {
    color: #fff;
    border-color: #fff;
  }
}

.data_ps_bg {
  padding: 0.5rem;
  background-color: customcolor.$system-divider;
  border-radius: 4px;
  text-align: justify;
  text-justify: distribute;
  text-indent: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > .section2 {
    margin-top: 0;
  }

  & > .section1 {
    margin-top: 0;
    color: #000;
  }
}

.data_ps_title {
  padding: 0.5rem;
  background-color: customcolor.$system-divider;
  text-align: justify;
  text-justify: distribute;
  text-indent: 0;
  margin-top: 1.5rem;
  border-top: 1px rgba(0, 0, 0, 0.2) solid;
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
}

.data_editor {
  padding: 0.5rem;
  border: 1px customcolor.$system-lighter solid;
  border-radius: 4px;
  text-align: justify;
  text-justify: distribute;
  text-indent: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > .section2 {
    margin-top: 0;
  }
}

.data-container-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.li-ps-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.aeps_alert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: #fff8e1;

  > .item {
    display: flex !important;
    gap: 8px !important;
    text-align: justify;
    padding: 1rem 0.5rem;

    .material-icons {
      color: customcolor.$primary;
    }
  }
}

.disabled-click {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

/* 在不同尺寸下的設定 */

@media (max-width: 600px) {
}

// 可點擊樣式，先放著之後再整
.clickable {
  &:hover {
    background-color: customcolor.$system-divider;
    cursor: pointer;
  }
}
