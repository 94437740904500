@use 'src/scss/themes/_custom-color.scss' as customcolor;

// 使用list模擬table

.list-table {
  .mat-mdc-subheader {
    display: flex;
    font-weight: bold;
    margin: 0 !important;
  }

  .mat-mdc-list-item + .mat-mdc-list-item {
    border-top: 1px solid customcolor.$system-divider;
  }

  .mat-mdc-list-item {
    height: auto !important;
    padding: 0.25rem 0;
    min-height: 48px;
  }

  span {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      // color: customcolor.$system-dark;
      flex: 1;
    }
  }

  .panel-icon-spac {
    flex: none;
    width: 8px;
  }
}

.list-tool {
  text-align: center;
  width: 40px;

  + .list-tool {
    margin-left: 16px;
  }
}

// 有分欄的list
.col-list {
  .mdc-list-item__primary-text {
    white-space: wrap;
  }

  .list-title {
    display: flex;
    font-weight: bold;

    > div {
      flex: 1;
    }
  }

  .mat-mdc-list-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: auto !important;

    + .mat-mdc-list-item {
      border-top: 1px solid customcolor.$system-divider;
    }

    .list-content-item {
      display: flex;
      align-items: stretch;
      padding: 0.5rem 0;

      .mdc-checkbox {
        margin-top: -0.5rem;
      }
    }
  }
}
