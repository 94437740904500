@use 'src/scss/themes/_custom-color.scss' as customcolor;

/* 拖拉效果 */
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/* 對正在排序的項目進行動畫處理 */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* 為已丟棄的項目設置動畫 */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

// 防止圖片被拖拉
img {
  -webkit-user-drag: none;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  gap: 8px;

  .gallery-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: customcolor.$system-divider;

    .gallery-img {
      cursor: pointer;
      width: auto;
      height: 12rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  img {
    width: auto;
    height: 12rem;
    object-fit: contain;
  }

  .cdk-drag-placeholder {
    opacity: 0.33;
  }
}

//帶拖拉＆編號的動態列表
.add-drop-ol {
  list-style-type: none;
  padding-left: 0 !important;

  li {
    padding-left: 0;
    display: flex;
    gap: 8px;

    .drag_handle {
      margin-top: 1rem;
    }

    .li-bullet {
      margin-top: 1rem;
      color: #98635e;
      display: inline-block;
    }

    app-text-multi-lines {
      flex: 1;
    }

    .mdc-icon-button {
      margin-top: 4px;
    }
  }
}

// 拖拉位置預覽
.drag-placeholder {
  min-height: 64px;
  background: #ccc;
  border: dotted 3px #999;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// 拖拉預覽
.drag-preview {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

//跨區拖拉時須設定目的地的最小高度
.cross-drop {
  min-height: 64px;
}
