@use 'src/scss/utils/_font.scss' as font;
@use 'src/scss/themes/_custom-color.scss' as customcolor;

//直向 tab 設定

.vertical_tab {
  display: flex !important;
  flex-direction: row !important;
  padding-bottom: 6rem;

  .tablist {
    width: 20%;
    min-width: 5.4rem;
    background-color: #e0e0e0;
    float: left;
    color: #212121;

    .tablist-title {
      padding: 16px 0 4px 4px;
      text-indent: 0;
      margin: 0;
      color: rgba(0, 0, 0, 0.54);
      cursor: auto;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 !important;
    }

    li {
      cursor: pointer;
      padding: 8px 8px 8px 32px;
      text-align: left;
      text-indent: -4px;
      margin-bottom: 0;

      &:before {
        content: '';
        display: none;
      }

      .bullet {
        color: #98635e;
        display: inline-block;
        width: font.$base-font-size;
        margin-left: 0 - font.$base-font-size;
        margin-right: 4px;
      }
    }
  }

  .column_right {
    padding-left: 3rem;
    width: 100%;
  }

  .active {
    cursor: pointer;
    background-color: #fafafa;
  }
}

.mat-mdc-card.vertical_tab {
  padding: 0 !important;

  .column_right {
    padding: 1rem !important;
  }
}

.option-num {
  color: customcolor.$system-dark;
  width: 2rem;
}

.on-device {
  display: none !important;
  margin-bottom: 1.5rem;
}

@media (max-width: 600px) {
  .tablist {
    display: none;
  }

  .column_right {
    padding-left: 0 !important;
  }

  .on-device {
    display: block !important;
  }
}
