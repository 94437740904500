@use 'src/scss/themes/_custom-color.scss' as customcolor;

/* 主要頁面 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  -ms-overflow-style: none;
  overflow: auto;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: customcolor.$surface;
}

.center {
  display: block;
  text-align: center !important;
}

.center h1,
.center h2 {
  width: 100%;
  text-align: center !important;
}

.title-tool {
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  & > button {
    margin-top: 0.25rem;
    margin-bottom: 1rem !important;
  }

  .item {
    display: flex;
    align-items: flex-start;
  }

  a {
    margin-top: 4px;
  }
}

[hidden] {
  display: none !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}

.icons {
  width: 24px;
}

.mat-mdc-chip {
  white-space: nowrap;
}

.mat-mdc-chip + .mat-mdc-chip {
  margin-left: 8px !important;
}

.nowrap {
  white-space: nowrap;
}

.mat-mdc-menu-panel {
  position: relative !important;
  overflow: unset !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}

.clear {
  clear: both;
}

/* 排版 */

pre {
  display: block;
  white-space: pre-wrap !important;
  margin: 0px !important;
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.mobile-display {
  display: none;
}

/* 在不同尺寸下的設定 */

@media (max-width: 600px) {
  .mobile-hidden {
    display: none !important;
  }

  .mobile-display {
    display: block;
  }

  .mobile-item {
    flex: 1 !important;
  }

  .mobile-full {
    width: 100%;

    .mat-mdc-form-field {
      margin-bottom: 3rem;
    }
  }

  .title-tool {
    flex-direction: column;
    gap: 0;
  }
}

.mat-mdc-tooltip {
  white-space: pre-line;
}

.mat-focused {
  .mat-mdc-standard-chip {
    background-color: customcolor.$system-light !important;
  }
}

.pointer {
  cursor: pointer !important;
}
